.insurance {
  background-color: #fff;
  border-radius: 12px;
  .header {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h4 {
      color: #00040a;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .body {
    margin: 8px 16px 16px;
    border-radius: 8px;
    overflow: hidden;
    .item {
      border-bottom: 1px solid #eaeaea;
      &:last-child {
        border-bottom: none;
      }
      @media (max-width: 600px) {
        svg {
          font-size: 18px;
        }
      }
      padding: 12px;
      background: #f9f9f9;
      color: #0c0c0c;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      align-items: center;
      gap: 16px;
      svg {
        color: #039855;
      }
    }
  }
}
