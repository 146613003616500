.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.switchesRow {
  display: flex;
  gap: 32px;
  align-items: center;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px !important;
  width: 100%;
  max-height: 24px;

  @media (max-width: 992px) {
    max-height: 100%;
  }
  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #00040a;
  }
}

.contentWrapper {
  display: flex;
  gap: 24px;

  @media (max-width: 992px) {
    flex-direction: column;
  }
}

.leftBox {
  flex: 1;
}

.featuresCard {
  background-color: #fff;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 566px) {
    display: flex;
  }
}

.featureItem {
  display: flex;
  gap: 12px;
  align-items: center;

  @media (max-width: 992px) {
    width: 100%;
    max-width: 318px;
  }

  .text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #101828;
  }
}

.icon {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
}

.rightBox {
  flex: 1;
}

.gratitudeCard {
  background: #fff;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.header {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #eaecf0;
  .title {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    color: #101828;
  }

  img {
    border-radius: 50%;
  }
}

.avatar {
  .ava {
    width: 40px !important;
    height: 40px !important;
  }

  position: relative;
  span {
    content: "";
    background: #12b76a;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1.5px solid #fff;
  }
}

.title {
  font-weight: 600;
}

.amountWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #eaecf0;

  .amount {
    background: #fff;
    padding: 14px 12px;
    border-radius: 8px;
    border: 1px solid #d0d5dd;
    display: flex;
    align-items: center;
    column-gap: 32px;

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .amount_text {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #101828;
      width: 40px;
      text-align: center;
    }
  }
}

.amountInput {
  padding: 0;
  text-align: center;
}

.note {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #101828;
  width: 100%;
  max-width: 217px;
}

.sliderWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;

  .agree {
    display: flex;
    align-items: start;
    column-gap: 8px;

    .agree_title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #667085;
      span {
        font-weight: 500;
        color: #101828;
      }
    }
  }
}
