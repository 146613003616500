.elements {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eaeaea;
}

.radio {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #00040a;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

.title {
  color: #6e6e6e;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.title + div {
  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.checkbox {
  color: #5b5b5b;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  margin-top: 12px;
  gap: 8px;
  align-items: flex-start;
}

.tip {
  margin-top: 32px;

  .price {
    border-radius: 8px;
    border: 1px solid #e8e8e8;
    padding: 6px;
    width: 240px;
    min-width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;

    @media (max-width: 600px) {
      width: 100%;
    }
    button {
      border-radius: 6px;
      background: #e9e9e9;
      width: 34px;
      padding: 7.78px 8px 8.22px 8px;
      border: none;
      cursor: pointer;
    }
    input {
      color: #0c0c0c;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      border: none;
      outline: none;
      text-align: center;
      @media (max-width: 600px) {
        color: #6e6e6e;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

.rateAgent {
  margin-top: 32px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eaeaea;
  .items {
    margin-top: 12px;
    display: flex;

    @media (max-width: 600px) {
      flex-direction: column;
    }

    gap: 12px;
    .item {
      width: 25%;
      display: flex;
      align-items: center;

      @media (max-width: 600px) {
        width: 100%;
      }

      border-radius: 8px;
      border: 1px solid #e8e8e8;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      &.active {
        border: 1px solid #039855;
        background: rgba(217, 5, 6, 0.05);
        p {
          color: #039855;
        }
      }
      p {
        color: #6e6e6e;
        font-size: 13px;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
}
