.box {
  position: sticky;
  top: 68px;

  .title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.44px;
    color: #00040a;
    margin-bottom: 12px !important;
  }

  .card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px #1018280d;
  }

  .info {
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    column-gap: 16px;

    .element {
      width: 100%;
      p {
        &:first-child {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          color: #667085;
        }

        &:last-child {
          color: #101828;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          margin-top: 2px;
        }
      }
      .status {
        padding: 2px 8px;
        border: 1px solid #fedf89;
        border-radius: 50px;
        background: #fffaeb;
        color: #b54708 !important;
        width: 63px;
        font-size: 12px !important;
        line-height: 18px !important;
        font-weight: 500 !important;
        &.success {
          background: #bbfbd0;
          color: #119c2b !important;
        }
      }
    }
  }

  .key {
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    color: #475467 !important;
  }
  .value_text {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: #475467 !important;
  }

  .total_text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #101828;
  }

  .check {
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;

    h4 {
      color: #101828;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .accordionInner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
      p {
        color: #323232;
        font-size: 14px;
        line-height: 18px;
      }
    }

    .value {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      padding: 8px 0;
      &.accordion {
        cursor: pointer;
        p {
          display: flex;
          align-items: center;
          gap: 5px;
          svg {
            transition: 0.3s all ease-in-out;
            color: #323232;
          }
        }
      }

      p {
        color: #323232;
        font-size: 16px;
        line-height: 18px;
        @media (max-width: 992px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    .total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #eee;
      padding: 16px 0;
      p {
        color: #323232;
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        @media (max-width: 600px) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
    button {
      border-radius: 8px;
      background: #039855;
      height: 48px;
      padding: 8px 16px;
      color: #fff;
      border: none;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
      line-height: 18px;
      cursor: pointer;
      margin-top: 10px;
    }
    .conditions {
      display: flex;
      align-items: start;
      column-gap: 8px;
      color: #667085;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-top: 16px;
      a {
        color: #0040c1;
        text-decoration-line: underline;
      }
    }
  }
}
