@import "./custom.less";

:root {
  --primary-color: #039855;
  --background-color: #f4f4f4;
  --text-color: #828282;
  --text-black: #0c0c0c;
  --rs-bg-active: #039855;
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

body::-webkit-scrollbar {
  width: 3px;
}

* {
  box-sizing: border-box !important;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Overrides */

.search-area .MuiFormControl-root {
  background-color: #fff !important;
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: none;
}

.search-area .MuiFormControl-root label {
  top: 5px;
}

.search-area .MuiFormControl-root fieldset {
  border: none !important;
}

.search-area .MuiFormControl-root .MuiInputBase-root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-shadow: none;
}

.search-area .MuiFormControl-root fieldset {
  width: 100%;
  height: 56px;
  border: none;
}

.MuiSelect-select {
  padding: 8px !important;
  font-size: 14px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(238, 242, 244, 1) !important;
}

.MuiRadio-root,
.MuiCheckbox-root {
  padding: 0;
}

.rs-picker-toggle {
  min-height: 44px;
  height: 100%;
  display: flex;
  color: #fff;
  min-width: 160px;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-btn-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border-color: var(--primary-color);
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
  top: 11px;
}

.rs-picker-toggle input {
  background-color: rgba(238, 242, 244, 1);
  opacity: 1;
  font-size: 16px;
  color: #000 !important;
}

.rs-picker-toggle input::placeholder {
  color: #000 !important;
}

.rs-picker-toggle .rs-stack {
  height: 100%;
}

.rs-picker-toggle .rs-picker-toggle-placeholder {
  color: #000;
}

.disabled {
  opacity: 0.5;
}

.image-gallery-icon {
  width: 36px !important;
  height: 36px !important;
  padding: 8px !important;
  border-radius: 50%;
  background-color: #fff !important;
  filter: none !important;
}

.image-gallery-right-nav {
  right: 16px !important;
}
.image-gallery-left-nav {
  left: 16px !important;
}

@media (max-width: 566px) {
  .image-gallery-right-nav {
    right: 4px !important;
  }
  .image-gallery-left-nav {
    left: 4px !important;
  }

  #hotel-gallery-pop .image-gallery-image {
    max-height: 422px !important;
    width: 100% !important;
    object-fit: cover !important;
    border-radius: 12px;
  }
}
.image-gallery-icon svg {
  height: 20px !important;
  width: 20px !important;
  stroke: #344054 !important;
}

#hotel-gallery-pop .image-gallery-image {
  max-height: 422px !important;
  width: 872px;
  object-fit: cover !important;
  border-radius: 12px;
}

.image-gallery-thumbnail-image {
  max-width: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important;
  object-fit: cover !important;
  border-radius: 8px;
}

.image-gallery-thumbnail {
  width: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important;
  border: none !important;
  opacity: 0.7;
  flex-shrink: 0;
}

.image-gallery-thumbnail:hover {
  border: none !important;
}

.image-gallery-thumbnail.active {
  max-width: 72px !important;
  min-height: 72px !important;
  max-height: 72px !important;
  border: none !important;
  opacity: 1;
}

#insurance .MuiSlider-root {
  height: 16px !important;
  border-radius: 0;
}

@media (max-width: 992px) {
  #insurance .MuiSlider-root {
    width: 100% !important;
    margin: 0 16px !important;
    margin-bottom: 16px !important;
    max-width: -webkit-fill-available !important;
  }
}

#insurance .MuiSlider-thumb::after {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid #ffffff;
}
#insurance .MuiSlider-thumb {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid #ffffff;
}
#insurance .MuiSlider-mark {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  background: #d0d5dd;
  transform: translateX(-14px) translateY(-13.5px);
  z-index: 999;
}
#insurance .MuiSlider-markActive {
  width: 26px;
  border-radius: 50%;
  height: 26px;
  background: #039855;
  opacity: 1;
}
#insurance .MuiSlider-rail {
  background: #d0d5dd;
}
