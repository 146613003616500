.box {
  display: flex;
  align-items: start;
  flex-direction: column;
  // padding: 24px;
  @media (max-width: 992px) {
  }

  .text_area {
    padding: 20px 24px;
    width: 100%;

    @media (max-width: 992px) {
      padding: 16px;
      max-height: 500px;
      overflow: auto;
    }

    .text {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: #101828;
    }
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    opacity: 0.5;
  }

  .input {
    background-color: #fff;
    width: 100%;
    padding: 16px;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px 0px #1018280d;
    border-radius: 8px;

    &:focus-visible {
      outline: none;
    }
  }

  .header {
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 24px;
    border-bottom: 1px solid #eaecf0;
    width: 100%;

    @media (max-width: 992px) {
      padding: 16px !important;
    }

    .add_user {
      background: #fff;
      border: 1px solid #eaecf0;
      box-shadow: 0px 1px 2px 0px #1018280d;
      padding: 12px;
      border-radius: 10px;
      width: 100%;
      max-width: 48px;
      height: 100%;
      max-height: 48px;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #101828;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: #475467;
    }
  }

  .body {
    max-height: 500px;
    overflow: auto;
    padding: 24px;
    width: 100%;
  }

  .footer {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 24px;
    width: 100%;
    border-top: 1px solid #eaecf0;
    justify-content: end;

    .save_button {
      color: #fff;
      border: 1px solid #fef3f2;
      background: #079455;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      border-radius: 8px;
    }

    .cancel_button {
      color: #344054;
      background: #fff;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px 0px #1018280d;
    }
  }
}
