.items {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  .item {
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    width: 50%;
    border-radius: 8px;
    @media (max-width: 600px) {
      width: 100%;
      padding: 16px;
    }
    .img {
      color: #00040a;
      font-size: 18px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 12px;
      @media (max-width: 600px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
