.ticket {
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  margin-bottom: 16px;
  cursor: pointer;
  transition: 0.3s border-color;
  max-width: 480px;
  width: 100%;
  // box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;

  &:hover {
    border-color: #c8c8c8;
  }

  &.onlyRead {
    cursor: default;
    &:hover {
      border-color: #eee;
    }
  }

  &.active {
    border-color: #027a48;
    background-color: #f9fef9;
  }

  &.more {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.ticketHeader {
  position: relative;
  background-color: #fa050a;
  color: #fff;
  padding: 16px 32px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -3px;
    width: 100%;
    height: 6px;
    box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
    background: radial-gradient(circle 3px at center, #fff 100%, transparent 0)
      repeat-x;
    background-size: 10px 6px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 40px;
    width: 40px;
    height: 40px;
    background-color: #f8f8f8;
    border-radius: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.ticketBody {
  position: relative;
  box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
  display: flex;
  gap: 16px;
  padding: 32px 24px;
  flex-direction: column;

  @media (max-width: 600px) {
    flex-direction: column;
  }
  &::before {
    content: "";
    position: absolute;
    right: -40px;
    top: -20px;
    width: 40px;
    height: 40px;
    background-color: #f8f8f8;
    border-radius: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
}

.flightSegment {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  min-width: 220px;

  .airline {
    display: flex;
    align-items: center;
    gap: 14px;

    img {
      width: 32px;
      height: 32px;
      object-fit: contain;
    }
    .airlineName {
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }
    .flightDuration {
      font-size: 14px;
      color: #666;
    }
  }

  .flightInfo {
    display: flex;
    align-items: flex-start;
    gap: 16px;

    .timeline {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 0;

      .dot {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #0085ff;
      }

      .line {
        width: 2px;
        width: 2px;
        display: flex;
        height: 100%;
        background: #0082ff;
        height: 70px;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .departure,
      .arrival {
        display: flex;
        align-items: flex-end;
        column-gap: 24px;

        .time {
          font-size: 18px;
          font-weight: 600;
          color: #000;
        }
        .date {
          font-size: 14px;
          color: #999;
        }
        .airport {
          font-size: 14px;
          color: #555;
        }
      }
    }
  }
}

.layover {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;

  .layoverLabel {
    background-color: #fff;
    color: #dc6803;
    padding: 2px 18px;
    font-weight: 600;
    border-radius: 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    border: 1.5px solid #dc6803;
  }
}

.roundtripSeparator {
  width: 100%;
  height: 0;
  border-top: 1px dashed #e67d30;
  margin: 0 4px;
}
