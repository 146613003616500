.box {
  width: 479px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  @media (max-width: 600px) {
    width: 100%;
    padding: 12px;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    opacity: 0.5;
  }
  p {
    color: #00040a;
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    line-height: 32px;
    margin: 16px 0 6px;
    @media (max-width: 600px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  span {
    color: #323232;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    display: block;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
}
