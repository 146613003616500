.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.44px;
  color: #00040a;
  margin-bottom: 12px !important;
}

.wrapper {
  border-radius: 8px;
  background: #fff;
  padding: 16px;

  display: flex;
  align-items: center;
  column-gap: 16px;

  @media (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }

  .contact {
    width: 49%;

    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      color: #303940;
      margin-bottom: 2px !important;
    }

    div {
      &:first-child {
        background: #fff;
      }
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}
