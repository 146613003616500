.header {
  height: 72px;
  padding: 12px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;

  @media (max-width: 992px) {
    padding: 16px !important;
    .nothing {
      width: 0;
      display: none;
    }
  }

  .nothing {
    padding: 12px 32px;
  }

  .container {
    width: 100%;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }

  @media (max-width: 992px) {
    .nothing {
      width: 0;
      display: none;
    }
  }

  .logo {
    width: 123px;

    @media (max-width: 992px) {
      width: 88px;
    }
  }

  .content {
    @media (max-width: 567px) {
      max-width: 200px !important;
    }
  }

  .avatar {
    position: relative;
    .ava {
      border-radius: 50%;
    }
    span {
      content: "";
      background: #12b76a;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      position: absolute;
      bottom: 0;
      right: 0;
      border: 1.5px solid #fff;
    }
  }

  .name {
    @media (max-width: 556px) {
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 500 !important;
    }
  }

  a {
    text-decoration: none !important;
    color: #475467 !important;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

    @media (max-width: 556px) {
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 500 !important;
    }
  }
}
